(function ($) {

    $('.hiddenfield').val(
        $('#jobtitle').html()
    );

    $(".additional").click(function () {
        $(this).toggleClass('active');
        $(".additional-sponsorships").slideToggle("fast");
    });

    if ($("#ajax-content").length > 0) {
        $("#nav li a").click(function () {
            $("#ajax-content").empty().append("<div id='loading'><img src='/wp-content/themes/parts-canada-v2/assets/images/puff.svg' alt='Loading' /></div>");

            $("#nav li a").removeClass('current');
            $(this).addClass('current');

            var myUrl = $(this).attr("href") + " #supplier-content";
            $("#ajax-content").load(myUrl);

            return false;

        });

        $("#nav li:nth-child(2)").find('a').trigger('click');

    }

    if ($(".carousel").length > 0) {

        $('.carousel .container').slick({
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 1000,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 400,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }

            ]
        });

    }

    // Toggle class: hamburger
    $(".hamburger").click(function () {
        $(this).toggleClass('active');
        $("nav.all").toggleClass('active');
        $("nav.all").slideToggle("fast");
        $("body").toggleClass('nav-open');
    });

}(jQuery));
